<template>
	<v-app :style="{ background: $vuetify.theme.themes.light.background }">
		<v-app-bar color="grey darken-3" dark app clipped-left>
			<v-icon style="margin-right: 30px">{{ "mdi-account" }}</v-icon>
			<v-toolbar-title style="cursor: pointer" @click="$router.push('/')"
				>CityView</v-toolbar-title
			>
			<v-spacer></v-spacer>
		</v-app-bar>
		<v-main>
			<div class="error">エラーが発生しました</div>
		</v-main>
		<v-footer color="grey darken-3" dark app>
			<v-spacer></v-spacer>
			©2021
			<a
				style="margin-left: 4px; color: #ffffff"
				href="https://www.vegetalia.co.jp/"
			>
				vegetalia</a
			>, inc.
		</v-footer>
	</v-app>
</template>

<script>
export default {
	mounted() {
	},
};
</script>
<style>
.error {
	width: 100%;
	font-size: 18;
	margin: auto;
	text-align: center;
	padding: 20px;
}
</style>